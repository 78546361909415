@import '~style/common';

.Container {
  padding: $metrics-s 0;

  @include media-tablet {
    padding:  $metrics-l 0;
  }
}
.BigHeading {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-M;
  margin-top: $metrics-xs;
  text-align: start;
  margin-bottom: $metrics-s;
  margin-left: $metrics-s;
  text-transform: uppercase;

  @include media-small-desktop {
    @include Font-Head-N;

  }
}

.PrimaryColor{
  color:  $color-primary;
}


.Heading {
  @include grid-column(12);
  @include Font-Body;
  text-align: start;
  margin-bottom: $metrics-s;
  @include media-small-desktop {
    width:60%

  }

}

.SubHeading {
  @include grid-column(12);
  @include Font-Body;
  text-align: start;
  margin-bottom: $metrics-s;

  @include media-tablet {
    text-align: center;
    margin-bottom: $metrics-xl;
  }
}

.ColumnsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: $metrics-m 0
}

.Column {
  padding: $metrics-s 0;
  text-align: center;

  @include grid-column(6);

  @include media-tablet {
    @include grid-column(4);
  }
}
.TitleWithButtonWrapper{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &__ButtonPosition{
    display: flex;
    justify-content: center;
    width: 40%;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 50px;
  }
}
.Button{
  background-color: white;
  color: $color-primary;
  &:hover{
    opacity: 0.6;
  }
}
.BottomContactButtonSection{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

