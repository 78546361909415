@import '~style/common';

.Container {
  padding: $metrics-extra-l $metrics-s;
}

.Heading {
  color: $color-primary;
  text-align: center;
  @include Font-Head-M;
  margin-bottom: $metrics-m;

  @include media-small-desktop() {
    text-align: left;
    margin-bottom: $metrics-s;
    padding-left: $metrics-s;
    padding-right: $metrics-s;
  }
}

.Description {
  @include Font-Body;
  text-align: center;
  margin-bottom: $metrics-s;

  @include media-small-desktop() {
    margin-bottom: 0;
    text-align: left;
    padding: 0 $metrics-s;
    width: 50%;
  }
}

.ArticleBoxesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.ArticleBox {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(6);
  }

  @include media-small-desktop {
    @include grid-column(4);
  }
}
