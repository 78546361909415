@import '~style/common';

.PartnerBox {
  @include grid-column(3, $mobile-grid);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  margin: $metrics-s auto;

  @include media-tablet {
    @include grid-column(12);
    margin: $metrics-m auto $metrics-l;
  }

  .PartnerLogoBox {
    text-align: center;
    height: $metrics-l;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    .Link:focus {
      outline: none;
    }

    .PartnerLogo {
      max-width: 100%;
      max-height: 100%;
      padding: 0 $metrics-xs;
      filter: grayscale(1);
    }
  }
}
