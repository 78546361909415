@import "~style/common";

.Container {
  padding-top: $metrics-xl;
}

.ArticleBox,
.MoreArticleBox {
  width: calc(100% - 60px);
  float: left;
  margin-left: 30px;
  margin-bottom: $metrics-s;
  margin-top: $metrics-s;
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    &:first-of-type {
      margin-left: 0;
    }
    @include grid-column(6);
  }

  @include media-small-desktop {
    @include grid-column(4);
  }
}

.CtaContact {
  padding-bottom: $metrics-l;
}

.Partners {
  margin-top: $metrics-l + $metrics-s;
}

.MoreArticleBoxContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0 0 $metrics-m;

  @include media-tablet {
    padding: 0 $metrics-s 50px;
  }
}

.MoreArticleBox {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: $metrics-s;
}

.SectionHeader {
  @include sectionHeader;
  padding: 0 $metrics-s $metrics-s;
  margin-bottom: 0;

  @include media-tablet {
    padding: 0 0 $metrics-s;
  }
}

.WhatWeDo {
  margin-top: 50px;
}
