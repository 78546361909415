@import '~style/common';

.DefaultColors {
  background-color: $color-secondary;
  color: $color-white;
}

.LayoutStyle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $metrics-s;
  padding: $metrics-m 0;
  
  @include media-mobile {
    grid-template-columns: 3fr 2fr;;
  }

  @include media-tablet {
    grid-template-columns: 5fr 2fr 2fr 2fr;
  }
}

.Description {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
  margin-bottom: $metrics-m;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include Font-Body;
  .LogoWrapper {
    width: 75%;
    height: $metrics-l;
  }
  .Text {
    text-align: center;
  }
  @include media-mobile {
    width: 80%;
    grid-column-start: 1;
    grid-column-end: 2;
    align-items: flex-start;
    .LogoWrapper {
      width: $metrics-xxl;
      height: $metrics-m;
    }
    .Text {
      text-align: left;
    }
  }
  @include media-tablet {
    .LogoWrapper {
      width: 220px;
      height: $metrics-l;
    }
  }
}

.MainLinksContainer {
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  
  .Header {
    display: block;
    height: $metrics-l;
    @include Font-Body;
    font-weight: 500;
  }

}

.LinkContainer {
  @include Font-Body
}

.MainLinksContainer:last-of-type {
  grid-column-start: 1;
  grid-column-end: -1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: $metrics-s;
  .LinkContainer {
    margin: 0 $metrics-xs;
  }
  @include media-mobile {
    flex-direction: row;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    .LinkContainer {
      margin: 0 $metrics-xs 0 0;
    }
  }
  @include media-tablet {
    grid-column-start: 4;
    grid-column-end: -1;
    flex-direction: column;
    margin-top: $metrics-l;
    .LinkContainer {
      margin: 0 $metrics-m 0 0;
    }
  }
}

.SocialMedia {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-bottom: $metrics-xs;
  @include media-tablet {
    flex-grow: 1;
  }
}

.MainLink {
  @include Font-Body;
  display: block;
  color: $color-white;
  cursor: pointer;
  margin-bottom: $metrics-xs;
  &:hover {
    color: $color-white-opacity-50;
  }
}

.BottomText {
  grid-column-start: 1;
  grid-column-end: -1;
  padding-top: $metrics-s;
  border-top: 1px solid $color-white-opacity-50;
  text-align: center;
  a:link, a:visited, a:focus, a:active {
        color: $color-white;
        outline: none;
      }
  a:hover {
    color: $color-white-opacity-50;
  }
}