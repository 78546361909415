@import '~style/common';

.container {
  border-radius: $metrics-extra-l/2;
  background-color: $color-primary;
  display: flex;
  flex-direction: column;
  color: $color-white;
  padding: $metrics-s;
  min-height: 550px;
  @include media-tablet {
    padding: 2 * $metrics-s $metrics-s;
    width: 95%;
    height: 3 * $metrics-xxl;
    margin: 0;
  }
}

.containerWithLink {
  border-radius: $metrics-extra-l/2;
  background-color: $color-primary;
  display: flex;
  flex-direction: column;
  color: $color-white;
  padding: $metrics-s;
  min-height: 550px;
  @include media-tablet {
    padding: 2 * $metrics-s $metrics-s $metrics-s;
    width: 95%;
    height: $metrics-xxxl + $metrics-xxl;
    margin: 0;
  }
}

.wrapper {
  padding: 0 $metrics-xs;
  @include media-tablet {
    padding: 0;
  }
}

.wrapperImage {
  width: 100%;
  padding: 0 0 2 * $metrics-xs;
  @include media-tablet {
    padding: 0 0 20px $metrics-s;
  }
  .image {
    width: 90px;
  }
}

.title {
  margin: 20px 0;
  p {
    font-size: 25px;
    text-transform: uppercase;
    line-height: 1.1;
    @include media-tablet {
      font-size: 42px;
    }
  }
}

.ReadMore {
  width: 100%;
  background-color: $color-white;
  color: $color-secondary;
  font-weight: 500;
  bottom: $metrics-xs * 2;
}

.Link {
  margin-top: auto;
  cursor: pointer;
}
