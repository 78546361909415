@import '~style/common';

$rotation: rotateY(12deg) scaleX(1.01);

@keyframes hide {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes show {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes hideToRight {
  0%   { transform: translate(0); }
  100% { transform: translate(5vw) $rotation; }
}

@keyframes hideToLeft {
  0% { transform: translate(0); opacity: 1; }
  100% { transform: translate(-5vw) $rotation; }
}

@keyframes showToRight {
  0%   { transform: translate(-5vw) $rotation; }
  100% { transform: translate(0); }
}

@keyframes showToLeft {
  0% { transform: translate(5vw) $rotation; }
  100%   { transform: translate(0); }
}

@mixin showTimingFunction {
  animation-timing-function: ease-in-out;
  animation-duration: 0.65s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@mixin hideTimingFunction {
  animation-timing-function: ease-out;
  animation-duration: 0.65s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.Hide {
  animation: hide;
  @include hideTimingFunction;
}

.Show {
  animation: show;
  @include showTimingFunction;
}

.HideToRight {
  animation: hideToRight;
  @include hideTimingFunction;
}

.HideToLeft {
  animation: hideToLeft;
  @include hideTimingFunction;
}

.ShowToRight {
  animation: showToRight;
  @include showTimingFunction;
}

.ShowToLeft {
  animation: showToLeft;
  @include showTimingFunction;
}

.Layout {
  position: relative;

  @include media-mobile {
    padding-bottom: 10%;
  }

  @include media-tablet {
    padding-bottom: 0;
  }
}

.Quotation {
  color: $color-primary;
  border: none;
  width: 100%;
  p {
    font-weight: bold;
  }
  @include media-tablet {
    border-left: 5px solid $color-primary;
    padding-left: $metrics-sm;
    margin-left: $metrics-sm;
    width: 50%;
    p {
      font-size: 30px;
      line-height: 1.2;
    }
  }
}

.SliderWrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
  @include media-tablet {
    flex-direction: row;
  }
}

.ReversedOnMobile {
  display: flex;
  flex-direction: column-reverse;
  padding: 0;
  @include media-tablet {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
}

.SliderIntro {
  width: 100%;
  padding-bottom: $metrics-s;
  @include media-tablet {
    width: 50%;
    padding-bottom: 0;
  }
}

.Description {
  margin-top: $metrics-extra-l/2;
  font-size: 18px;
  line-height: 1.2;
}

.Image {
  height: $metrics-m;
  width: auto;
  padding: 0 $metrics-s;
  @include media-tablet {
    width: auto;
    height: 160px;
    max-width: 100%;
    padding: 0;
  }
}

.DescriptionSection {
  width: 100%;
  margin-top: $metrics-xs;
  clear: both;
  overflow: hidden;
  transition: height 1.5s ease-in-out;
  height: auto;
  margin-left: 0;
  @include media-tablet {
    @include grid-column(12);
    margin: 0;
  }
  @include media-regular-desktop {
    margin-top: 0;
  }
}

.Name {
  @include Font-Head-S;
  font-size: $fs-body;;
  @include media-small-desktop {
    @include Font-Head-N;
    font-size: $fs-body;
    margin-top: 0;
  }
}

.AssetContainer {
  height: 3*$metrics-extra-l;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include media-tablet {
    width: auto;
    transform: translateY(-50px);
  }
}

.AssetStyle {
  height: 3*$metrics-extra-l;
  object-fit: contain;
  object-position: bottom right;
  background: bottom right;
  background-size: contain;

  @include media-tablet {
    width: 5*$metrics-extra-l;
    height: 5*$metrics-extra-l;
    right: 0; 
  }
}
