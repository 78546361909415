@import '~style/common';

.Container {
  width: 100%;
  padding: $metrics-extra-l $metrics-s;
  padding-bottom: $metrics-xs;
}

.Heading {
  color: $color-primary;
  text-align: left;
  @include Font-Head-M;
  margin-bottom: $metrics-s;

  @include media-small-desktop() {
  }
}

.Description {
  @include Font-XS-Text;
  width: 100%;
  margin-bottom: $metrics-s;

  @include media-small-desktop() {
    width: 50%;
  }
}

.Comment {
  @include Font-Head-S;
  font-size: 1.8rem;

  @include media-small-desktop() {
    width: 50%;
  }
}

.DiamondSections {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media-tablet {
    margin-bottom: $metrics-extra-l;
    width: 100%;
  }
}
