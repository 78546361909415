@import '~style/common';

.LayoutStyle {
  padding: $metrics-s 0 $metrics-l;

  @include media-small-desktop {
    padding-top: $metrics-l;
    padding-bottom: $metrics-xxl;
  }
}

.Header {
  @include grid-column(12);
}

.ContactInfo {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.ActionsButtonsWrapper {
  display: flex;
}

.Heading {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-M;
  margin: $metrics-s 0 $metrics-s 0;

  @include media-tablet {
    @include grid-column(12);
    font-size: 32px;
    padding-left: 0;
    text-align: left;
    margin: $metrics-s 0 $metrics-s 0;
  }
}

.InfoContainer {
  @include grid-column(12);
  width: 100%;
  margin-left: 0;

  @include media-tablet {
    @include grid-column(12);
    margin-left: 0;
  }
}

.InfoHeading {
  @include Font-Head-S;
  margin-bottom: $metrics-s;
  color: $color-primary;
  font-size: $fs-body;
  line-height: 1.3;
  font-weight: bold;
  @include media-tablet {
    font-size: 55px;
  }
}

.Content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include media-tablet {
    flex-direction: row;
    padding: $metrics-s 0;
  }
}

.Form {
  margin-top: $metrics-l;
  @include media-tablet {
    @include grid-column(6);
    margin-top: $metrics-xl;
  }
}

.FormHeader {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-L;
  margin-top: 15vh;
  font-weight: normal;
  @include media-tablet {
    @include grid-column(10);
    @include grid-push(1);
    margin-top: 20vh;
    float: none;
  }
  float: none;
}

.Input {
  @include grid-column(12);
  margin-top: $metrics-xs;
  margin-left: 0;
}

.Button {
  width: 100%;
  margin-top: $metrics-extra-l/2;
  @include media-small-desktop {
    @include grid-column(12);
    margin-top: 0;
  }
}

.CheckBoxPanelVisible {
  position: relative;
  opacity: 1;
  transform: translateX(0);
  pointer-events: auto;
  transition: all, 0.5s ease-out;
  transition-delay: 0.3s;
}

.CheckBoxPanelHidden {
  position: absolute;
  opacity: 0;
  transform: translateX(-25px);
  pointer-events: none;
}

.Consent {
  @include grid-column(12);
  margin: $metrics-xs 0;
  font-size: 12px;
  line-height: 1.1;
}
