@import "~style/common";

.Container {
  cursor: pointer;
  position: relative;
  padding-bottom: $metrics-xs;

  &:first-of-type {
    padding-right: $metrics-sm;
  }
}

.OptionsWrapper {
  display: none;

  &.Open {
    display: block;
  }
}

.Placeholder {
  @include Font-Head-S;
  font-size: $fs-body;
  color: #2d2e35;
  padding-bottom: $metrics-xs;
  border-bottom: 2px solid $color-horizontal-line;
  width: 300px;
  display: flex;
  justify-content: space-between;
  transition: opacity 0.2s ease;
  

  &:hover {
    opacity: 0.7;
  }

  &.Selected {
    color: $color-primary;
  }
}

.OptionsList {
  list-style: none;
}

.OptionsWrapper {
  position: absolute;
  bottom: -$metrics-xxl;
  left: 0;
  width: 100%;
  max-height: $metrics-xxl;
  overflow-y: scroll;
  z-index: 1;
  background: #fff;
  padding: 0 $metrics-xs;
}

.ArrowButton {
  position: relative;
  left: 0;
  top: -2px;
  i {
    transition-duration: $opacity-transition-time;
    padding: 5px;
  }

  &.Active {
    i {
      transform: rotate(225deg);
    }
  }

  &.Selected {
    i {
      border-color: $color-primary;
    }
  }
}

.Checkbox {
  @include Font-XS-Text;
  display: block;
  color: #2d2e35;
  padding: $metrics-xs 0 $metrics-xs $metrics-s;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;

  &.Checked {
    font-weight: 500;
    .Checkmark::after {
      display: block;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .Checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #2d2e35;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.04);
    &::after {
      content: "";
      position: absolute;
      display: none;
      font-family: FontAwesome;
      font-weight: normal;
      font-style: normal;
      top: 0;
      left: 4px;
      width: 3px;
      height: 10px;
      text-decoration: none;
      border: solid #6450D6;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}
