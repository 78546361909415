@import '~style/common';

.HorizontalLine {
  @include grid-column(12);
  float: none;
  border-top: 1px solid $color-horizontal-line;
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin-left: 0;
}
