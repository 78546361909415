@import '~style/common';

.Container {
  @include media-small-desktop {
    padding-bottom: $metrics-xs;
  }
}

.HorizontalLine {
  @include media-less-than-tablet {
    width: 100%;
    margin: 0;
  }
}

.DropDown {
  width: 100%;
  margin: 0;

  @include media-tablet {
    @include grid-column(12);
  }
}

.Sections {
  @include grid-column(3, $mobile-grid);
  @include Font-Body;
  line-height: 1rem;
  display: flex;
  flex-direction: column;

  @include media-small-desktop {
    @include grid-column(12);
    flex-direction: row;
    justify-content: center;
  }
}

.Section {
  padding: $metrics-s $metrics-xs;
  cursor: pointer;
  list-style: none;
}

.Section:hover {
  color: $color-secondary-opacity-20;
}

.Active, .Active:hover {
  cursor: default;
  font-weight: 500;
  color: $color-secondary;
}
