@import '~style/common';

.Container {
  width: 100%;
}

.HeadingBox {
  width: 100%;
  padding: $metrics-m 0;

  @include media-tablet() {
    padding: $metrics-extra-l $metrics-s;
  }
}

.Heading {
  @include Font-Head-M;
  color: $color-primary;
  text-align: center;

  @include media-tablet() {
    @include Font-Head-N;
    text-align: left;
  }
}

.BlocksBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
