@import '~style/common';

.Navbar {
  width: 100%;
  position: sticky;
  z-index: 2;
}

.WhiteBackgroundShow {
  background-color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: $mobile-navbar-height;
  transition: all .8s;
}

.WhiteBackgroundHide {
  background-color: $color-white;
  position: absolute;
  top: -$mobile-navbar-height;
  left: 0;
  width: 100%;
  height: $mobile-navbar-height;
  transition: all .8s;
}

.CollapsedMenuContainer {
  @include grid-column(3, $mobile-grid);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: $metrics-xs * 2;
}

.LogoContainer {
  z-index: 2;

  .Logo {
    cursor: pointer;
    height: $logo-height;
    z-index: 2;
    background-size: contain;
  }
}

.CollapsedMenuBurger {
  position: relative;
  display: block;
  float: left;
  width: 24px;
  height: 18px;
  z-index: 2;
  cursor: pointer;

  border-top: 2px solid;
  border-bottom: 2px solid;

  color: inherit;
  font-size: 0;

  transition: all 0.2s cubic-bezier(0.35, 0.85, 0.5, 0.95) !important;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;

    position: absolute;
    top: 50%;
    left: 50%;

    background: currentColor;

    transform: translate(-50%, -50%);
    transition: transform 0.2s cubic-bezier(0.35, 0.85, 0.5, 0.95) !important;
  }

  &.BurgerOpen {
    border-color: transparent;

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.MobileNavBackground {
  position: fixed;
  top: $metrics-l;
  left: 0;
  width: 100%;
  height: auto;
  background-color: $color-white;
}

.MobileNavShow {
  position: fixed;
  top: $metrics-l;
  transition: all .9s;
}

.MobileNavHide {
  position: fixed;
  top: -100%;
  transition: all .9s;
}

.MenuContainer {
  @include grid-column(3, $mobile-grid);
  float: none;
  padding: $metrics-s 0;
}

.MainLinksContainer {
  padding: 0 0 $metrics-s;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;

  @include media-tablet {
    @include grid-column(8);
    @include grid-push(2);
    float: none;
  }
}

.MainLink {
  @include Font-Head-S;
  margin: $metrics-xs 0;

  @include media-small-desktop {
    margin: 0 $metrics-s;
  }

  > a {
    color: $color-secondary;

    &:focus {
      color: $color-secondary;
    }
  }
}

.NavButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MobileNavHorizontalLine {
  margin-left: 0;
}

.SocialIcons {
  display: flex;
  justify-content: space-between;
  margin-top: $metrics-s;
}

.Icon {
  @include Font-Body;
  margin: 0 $metrics-s/2;
  color:$color-primary;

  &:hover {
    color: $color-secondary-opacity-20;
  }
}

.LowerIcon {
  margin-top: 2px;
}

.ContactNavButton {
  @include grid-column(3, $mobile-grid);
  max-width: $metrics-xl + 2 * $metrics-l;
  margin-left: 0;
  margin-bottom: $metrics-m;

  @include media-mobile {
    width: initial;
  }
}
