@import '~style/common';

.Wrapper {
  position: relative;
  height: auto;
  overflow: hidden;
}

.Container {
  height: calc(100vh - 65px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

  @media (min-width: 1500px) {
    height: calc(100vh - 110px);
  }
}

.BackgroundImage {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  height: 100vh;
}

.Heading {
  margin-left: 10%;
  margin-right: 10%;
  color: $color-primary;

  @include Font-Head-M;

  @include media-regular-desktop {
    @include Font-Head-N;
  }

  @include media-tablet {
    @include Font-Head-N;
  }
}

.Description {
  @include Font-Body();
  margin-left: 10%;
  margin-right: 10%;
  margin-top: $metrics-s;
}

.BtnLink {
  margin-top: $metrics-m;
  height: $metrics-m;
  border-radius: $metrics-s;
  border: 1px solid $color-primary;
  width: $metrics-xxl + $metrics-sm;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  @include Font-Body();
  color: $color-primary;
  transition: width 0.3s;

  &:hover {
    width: $metrics-xxl + $metrics-extra-l;
  }
}
