@import '~style/common';

.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $metrics-sm + $metrics-xs 0;

  @include media-tablet() {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: $metrics-sm + $metrics-xs;
  }
}

.Description {
  @include Font-Head-S();
  font-weight: 400;
  text-align: center;
  margin: 0 $metrics-xs $metrics-s $metrics-xs;

  @include media-mobile() {
    margin-bottom: $metrics-s;
  }

  @include media-tablet() {
    text-align: left;
    margin-bottom: 0;
  }
}

.BtnLink {
  margin: 0 $metrics-s;
  width: $metrics-xxl;

  @include media-mobile() {
    width: $metrics-xxl;
  }
}
