@import '~style/common';

.Paragraph {
  @include Font-Body;
}

.HeaderExtraLarge {
  @include Font-Head-XL;
}

.HeaderLarge {
  @include Font-Head-L;
}

.HeaderRegular {
  @include Font-Head-M;
}

.HeaderSmall {
  @include Font-Body;
}

.UnorderedList {
  @include Font-Body;
}

.ListItem {
  margin-left: ($metrics-s + $metrics-xs) / 2;
  margin-top: $metrics-xs;
  color: $color-primary;
  font-weight: 500;

  span {
    position: relative;
    color: $color-secondary;
    font-weight: 300;
  }
}

.RichTextParser {
  a {
    color: $color-primary;
    cursor: pointer;
  
    &:active, &:focus, &:visited {
      color: $color-primary;
    }
  
    &:hover {
      color: $color-primary-lighten-20;
    }
  }
}

