@import "~style/common";

.Container {
  display: flex;
  margin-top: $metrics-sm;
  flex-direction: column;
  min-width: 338px;
}

.Asset {
  margin: 0;
}

.AssetWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.SlideNumber {
  color: $color-primary;
  @include Font-Head-N;
  position: absolute;
}

.Description {
  padding: 0 $metrics-s;
  @include Font-XS-Text;
  margin-top: $metrics-sm;
  text-align: center;

  @include media-tablet() {
    text-align: center;
  }
}

.Arrow {
  position: absolute;
  right: 0;
}
