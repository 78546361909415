@import '~style/common';

.Container {
  position: relative;
  padding: $metrics-m $metrics-s;

  @include media-tablet() {
    padding: $metrics-extra-l $metrics-s;
  }

  @include media-small-desktop() {
    padding-right: 0;
  }
}

.ContainerReversed {
  position: relative;
  padding: $metrics-m $metrics-s;

  @include media-tablet() {
    padding: $metrics-extra-l $metrics-s;
  }

  @include media-small-desktop() {
    padding-left: 0;
  }
}


.GridContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: $metrics-xl $metrics-xxl * 2 1fr;
  gap: $metrics-s 0;

  @include media-small-desktop() {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 2.5fr;
    gap: 0 $metrics-s;
  }

}

.Heading {
  color: $color-primary;
  grid-row: 1;
  grid-column: 1;
  text-align: center;
  @include Font-Head-M;

  @include media-small-desktop() {
    margin-bottom: $metrics-s;
    text-align: left;
    grid-row: 1;
    grid-column: 1;
  }
}

.HeadingReversed {
  color: $color-primary;
  grid-row: 1;
  grid-column: 1;
  text-align: center;
  @include Font-Head-M;

  @include media-small-desktop() {
    margin-bottom: $metrics-s;
    text-align: left;
    grid-row: 1;
    grid-column: 2;
  }
}

.Description {
  grid-column: 1;
  grid-row: 3;
  @include Font-Body;

  @include media-small-desktop() {
    margin-top: $metrics-s;
    grid-column: 1;
    grid-row: 2;
  }
}

.DescriptionReversed {
  grid-column: 1;
  grid-row: 3;
  @include Font-Body;

  @include media-small-desktop() {
    margin-top: $metrics-s;
    grid-column: 2;
    grid-row: 2;

  }
  &:hover ~ #abc {
    transform: translateX(-$metrics-l);
  }
}

.Image {
  height: 100%;
  width: auto;
  justify-self: center;
  grid-column: 1;
  grid-row: 2;

  @include media-small-desktop() {
    height: auto;
    width: 100%;
    justify-self: flex-end;
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }


}

.ImageReversed {
  height: 100%;
  width: auto;
  justify-self: center;
  grid-column: 1;
  grid-row: 2;

  @include media-small-desktop() {
    height: auto;
    width: 100%;
    justify-self: flex-start;
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  &:hover ~ .Background {
    transform: translateX(-$metrics-l);
  }
}

.BackgroundReversed {
  height: $metrics-xxxl;
  position: absolute;
  left: $metrics-m;
  bottom: -$metrics-m;
  z-index: -1;
  transition: transform 0.3s;
}

.Background {
  height: $metrics-xxxl;
  position: absolute;
  right: $metrics-m;
  top: -$metrics-m;
  z-index: -1;
  transition: transform 0.3s;
}

