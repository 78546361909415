@import '~style/common';

.Header {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-tablet {
    @include grid-column(10);
  }

  @include media-small-desktop {
    @include grid-column(9);
    @include grid-push(1);
  }

  a {
    cursor: pointer;
  }

  .Tag {
    transition-duration: 0.15s;
  }

  a:hover .Tag {
    background-color: $color-secondary-opacity-10;
  }
}

.LawTags {
  padding-top: $metrics-s;
}

.Title {
  @include Font-Head-M;
  margin: $metrics-s 0 $metrics-xs;

  @include media-mobile {
    @include Font-Head-N;
  }

  @include media-small-desktop {
    @include Font-Head-L;
    margin: $metrics-m 0 $metrics-s;
  }
}

.Date {
  @include Font-Body;
  display: block;
  margin: $metrics-s 0;

  @include media-tablet {
    @include Font-XS-Text;
    display: inline-block;
    margin: 0 $metrics-s;
  }
}

.ImageContainer {
  width: 100%;
  height: 450px;
}

.Image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: top right;
  background-size: cover;
}

.Client {
  margin-bottom: $metrics-s;

  @include media-small-desktop {
    margin-bottom: $metrics-m;
  }
}
