@import '~style/common';

.Container {
  border-top: 1px solid $color-horizontal-line;
  border-bottom: 1px solid $color-horizontal-line;
  width: 100%;
  position: relative;
  transform &:first-of-type {
    border-top: 2px solid $color-horizontal-line;
  }

  &:last-of-type {
    border-bottom: 2px solid $color-horizontal-line;
  }
}

.Title {
  @include Font-Head-S();
}

.Description {
  @include Font-Body;
  margin-bottom: $metrics-s;
}

.FlexContainer {
  margin: $metrics-s 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CarouselArrow i {
  transition: all 0.45s ease-in;
  transform: rotate(45deg);
}

.CarouselArrowOpened i {
  transform: rotate(225deg);
}
