@import '~style/common';

$rotation: rotateY(12deg) scaleX(1);

@keyframes hide {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes show {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes hideToRight {
  0%   { transform: translate(0); }
  100% { transform: translate(5vw) $rotation; }
}

@keyframes hideToLeft {
  0% { transform: translate(0); opacity: 1; }
  100% { transform: translate(-5vw) $rotation; }
}

@keyframes showToRight {
  0%   { transform: translate(-5vw) $rotation; }
  100% { transform: translate(0); }
}

@keyframes showToLeft {
  0% { transform: translate(5vw) $rotation; }
  100%   { transform: translate(0); }
}

@mixin showTimingFunction {
  animation-timing-function: ease-in-out;
  animation-duration: 0.65s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@mixin hideTimingFunction {
  animation-timing-function: ease-out;
  animation-duration: 0.65s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.Hide {
  animation: hide;
  @include hideTimingFunction;
}

.Show {
  animation: show;
  @include showTimingFunction;
}

.HideToRight {
  animation: hideToRight;
  @include hideTimingFunction;
}

.HideToLeft {
  animation: hideToLeft;
  @include hideTimingFunction;
}

.ShowToRight {
  animation: showToRight;
  @include showTimingFunction;
}

.ShowToLeft {
  animation: showToLeft;
  @include showTimingFunction;
}

.Layout {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @include media-tablet {
    padding-bottom: 0;
  }
}

.SlideTitle {
  @include grid-column(3, $mobile-grid);
  float: none;
  display: flex;
  margin: $metrics-s 0 $metrics-s $metrics-s;
  @include media-tablet {
    margin-left: 0;
  }
}

.SlideWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: $metrics-xxl;
  transition: ease-out 0.45s;
  padding-bottom:$metrics-s ;
  @include media-tablet {
    min-height: 2*$metrics-xxl+$metrics-s;
    width: auto;
  }

  @include media-mobile {
    flex-direction: row;
  }
}

.Name {
  @include Font-Head-S;
  text-align: left;
  padding-bottom: $metrics-s;
  @include media-tablet {
    @include Font-Head-M;
    padding-bottom: 0;
  }
 
}

.Position {
  @include Font-Body;
  font-weight: 500;
  font-style: italic;

  @include media-small-desktop {
    @include Font-Head-S;
    margin-bottom: $metrics-xs;
    font-style: italic;
  }

  @include media-regular-desktop {
    margin-bottom: $metrics-s;
    font-style: italic;
  }
}

.TextWrapper {
  width: 120%;
  margin-left: -$metrics-m;
  text-align: center;
  order: 1;
  @include media-tablet {
    margin-left: 0;
    width: 80%;
  }

  @include media-mobile {
    order: 0;
  }
}

.Description {
  margin: $metrics-xs $metrics-s 0;
  animation: show 0.45s ;
  text-align: left;
  min-height: $metrics-xl + $metrics-m;
  font-size: $lh-xs-text;
  width: 100%;
  transform: scaleY(1);
  @include media-tablet {
    width: inherit;
    margin-left: 0;
  }
}

.FullHeightDescription {
  height: 100%;
}

.LinkedInLink {
  margin-top: $metrics-xs + 4px;
}

.AssetContainer {
  overflow: hidden;
  display: flex;
  justify-content: center;
  @include media-tablet {
    width: $metrics-xxxl;
    justify-content: flex-start;
  }
}

.AssetStyle {
  object-fit: cover;
  background-size: contain;
  width: $metrics-xxxl;
  max-height: 3*$metrics-extra-l;
  border-radius: $metrics-sm;

  @include media-tablet {
    margin-top: $metrics-m;
    max-height: 2*$metrics-xxl;
    width: 100%;
    border-radius: $metrics-extra-l/2;
  }
}

.AssetOverButtonStyle {
  height: 2*$metrics-xxxl;
}

.Anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-secondary;
  position: relative;
  border: 1px solid $color-secondary;
  width: 55px;
  height: 55px;
  @include Font-Body;
}

.ReadMoreButtonContainer {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(7);
  }

  position: relative;
  height: 0;
}

.ReadMoreButton {
  @include Font-Head-M;
  font-weight: 500;
  width: 100%;
  background: inherit;
  color: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  text-align: center;
  margin-left: $metrics-s;
  transition: ease-out 0.45s;
  transform: scaleY(1);
}


.HiddenButton {
  color: transparent;
  pointer-events: none;
}
