@import '~style/common';

.MobileNav {
  display: flex;

  @media (min-width: 1250px) {
    display: none;
  }
}

.DesktopNav {
  display: none;

  @media (min-width: 1250px) {
    display: flex;
  }
}
