@import '~style/common';

.Container {
  display: flex;
  flex-flow: column;
  padding: 0;
  margin-bottom: $metrics-l;
  color: $color-secondary;
  width: 100%;
  @include hover-opacity;

  @include media-mobile {
    padding: 0 $metrics-s;
  }

  @include media-small-desktop {
    flex-flow: row;
    margin-bottom: $metrics-xs;
    margin-top: $metrics-l;
  }
}

.RelativeAssetContainer {
  position: relative;
  height: 285px;
  max-width: 100%;
  width: 100%;

  @include media-tablet {
    height: 485px;
    max-width: 630px;
    min-width: 630px;
    padding-right: 90px;
  }
}

.ImageContainer {
  height: 100%;
  width: 100%;
}

.Image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: top right;
  background-size: cover;
}

.Tags {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  position: absolute;
  left: 0;
  bottom: 0;
}

.Title {
  @include Font-Head-S;
  padding: $metrics-s + $metrics-xs 0 $metrics-s 0;
}

.BottomText {
  @include Font-Body;
  padding-top: $metrics-s;
  padding-bottom: 40px;
}
.BtnLikeLink{
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
  color: #FFFFFF;
  background-color: #6450D6;
  border-radius: 24px;
  @include Font-Body;

  @include media-small-desktop {
    align-self: flex-end;
  }
}

.TextContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 $metrics-s;
}

.LawTags {
  display: flex;
}

.SmallBox {
  flex-flow: column;

  .SmallRelativeAssetContainer {
    @include media-less-than-tablet {
      @include grid-collapse;
    }
  
    position: relative;
    height: $metrics-xxl + $metrics-l;
  }

  .TextContainer{
    padding: 0;
  }
}