@import '~style/common';

.Language {
    @include Font-Body;
    cursor: pointer;

    &:visited {
        color: $color-secondary;
    }
}

.MobileView {
    margin-bottom: $metrics-s;
}
