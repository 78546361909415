@import '~style/common';

.Container {
  width: 100%;
  padding: $metrics-m $metrics-s;

  @include media-tablet() {
    width: 50%;
  }

  @include media-small-desktop() {
    padding: $metrics-xl $metrics-xl $metrics-xl $metrics-extra-l;
  }
}

.Title {
  @include Font-Head-S;
  margin-bottom: $metrics-xs * 2;

  &:hover + .Line {
    transform: translateX(0);
    width: 100%;
  }
}

.Description {
  @include Font-XS-Text;
  margin-top: $metrics-xs * 2;
  font-size: 1.8rem;
  line-height: 3.2rem;
}

.Line {
  border: 1px solid $color-primary;

  @include media-small-desktop() {
    width: $metrics-extra-l;
    transform: translateX(-5rem);
    transition: width 0.3s, transform 0.3s;

    &:hover {
      transform: translateX(0);
      width: 100%;
    }
  }
}
