@import '~style/common';

.Button {
  @include Font-Body;
  color: $color-white;
  background-color: $color-primary;
  padding: $metrics-xs;
  outline: none;
  border: none;
  border-radius: $button-rounding;
  box-shadow: $shadow-05;
  cursor: pointer;
  transition-duration: 0.2s;
  width: 100%;

  @include media-mobile {
    width: $metrics-xxl + $metrics-m;
  }

}

.Button:hover {
  background-color: $color-primary-lighten-20;
  box-shadow: $shadow-10;
}
