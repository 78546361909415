@import "~style/common";
.MainContainer {
  position: relative;
  padding: 0px;
}
.Container {
  position: relative;
  padding: $metrics-s 0;

  @include media-tablet {
    padding: $metrics-l 0;
  }
}

.AssetContainer {
  position: absolute;
  height: 100%;
  width: 100%;
}

.BackgroundAsset {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.BigHeading {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-M;
  margin-top: $metrics-xs;
  text-align: start;
  margin-bottom: $metrics-s;
  margin-left: $metrics-s;
  text-transform: uppercase;

  @include media-small-desktop {
    @include Font-Head-N;
  }
}

.PrimaryColor {
  color: $color-primary;
}

.Heading {
  @include grid-column(12);
  @include Font-Body;
  text-align: start;
  margin-bottom: $metrics-s;
  @include media-small-desktop {
    width: 60%;
  }
}

.SubHeading {
  @include grid-column(12);
  @include Font-Body;
  text-align: start;
  margin-bottom: $metrics-s;

  @include media-tablet {
    text-align: center;
    margin-bottom: $metrics-xl;
  }
}

.ColumnsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0 $metrics-sm;

  @include media-small-desktop {
    padding: 0 0 50px 0;
  }
}

.Column {
  padding: $metrics-s 0;
  text-align: center;

  @include grid-column(6);

  @include media-tablet {
    @include grid-column(3);
  }
}
.ContactNavButtonWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  .ContactNavButton {
    background-color: #ffffff;
    color: #6450d6;
    &:hover {
      opacity: 0.8;
    }
  }
}
