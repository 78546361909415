@import '~style/common';

.LogoContainer {
  cursor: pointer;
  z-index: 2;
}

.Logo {
  height: $logo-height;
}

.Navbar {
  width: 100%;
  padding: $metrics-s;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 2;
  @media (min-width: 1500px) {
    padding: $metrics-s $metrics-l;
  }

  .WhiteBackgroundShow {
    background-color: $color-white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $desktop-navbar-height;
    transition: all 0.8s;
  }

  .WhiteBackgroundHide {
    background-color: $color-white;
    position: absolute;
    top: -$desktop-navbar-height;
    left: 0;
    width: 100%;
    height: $desktop-navbar-height;
    transition: all 0.8s;
  }

  .NavButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    z-index: 2;

    .MainLinksContainer {
      list-style-type: none;
      display: flex;
      margin-right: 0;

      @media (min-width: 1500px) {
        margin-right: 0;
      }

      .MainLink {
        @include Font-Body;
        margin: 0 $metrics-s/2;
        font-size: 1.5rem;

        @media (min-width: 1500px) {
          font-size: 1.8rem;
          margin: 0 $metrics-xs;
        }

        > a {
          color: $color-secondary;
        }

        &:last-of-type {
          margin-right: 2rem;
        }
      }
    }

    .SocialIcons {
      display: flex;
      justify-content: space-between;

      .Icon {
        @include Font-Body;
        margin: 0 $metrics-xs;

        @include media-regular-desktop {
          margin: 0 calc(#{$metrics-s} / 2);
        }
        color: $color-primary;
        cursor: pointer;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: $metrics-s / 2;
          @include media-regular-desktop {
            margin-right: $metrics-s;
          }
        }

        &:hover {
          color: $color-secondary-opacity-20;
        }
      }

      .LowerIcon {
        margin-top: 2px;
      }
    }

    .ContactNavButton {
      margin-left: $metrics-s / 2;
      @include media-regular-desktop {
        margin-left: $metrics-s;
      }
    }
  }
}
