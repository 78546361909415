@import '~style/common';

.Wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  bottom: 0px;
}

.Title {
  @include Font-Body;
  color: $color-white;
  margin-bottom: $metrics-xs;
 
  @include media-tablet {
    margin-bottom: $metrics-xs;
  }
}

.Separator {
  margin: 0 $metrics-xs;
}

.SocialMedias {
  height: $metrics-s;
  a {
    margin-right: 0;
    margin-left: $metrics-xs;
    cursor: pointer;

    svg path {
      fill: $color-white;
      transform: scale(75%)
    }

    &:hover {
      svg path {
        fill: $color-dark-grey;
      }
    }
  }

  @include media-tablet {
    a {
      margin-left: 0;
  }
    > a:first-of-type {
      margin-left: 0;
    }
  }
}