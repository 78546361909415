@import '~style/common';

.Container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ContactNavButton {
  margin-left: $metrics-s;
}

.Header {
  @include Font-Head-S;
  color: $color-primary;
}

.Text {
  @include Font-Head-S;
  font-weight: 400;
  text-align: center;
  font-size: $fs-body;
  padding: $metrics-xs 0 $metrics-s;
}