@import '~style/common';

.Container {
  padding: $metrics-extra-l $metrics-s;

  @include media-small-desktop() {
    padding-right: 0;
  }
}

.Heading {
  color: $color-primary;
  text-align: left;
  @include Font-Head-M;

  @include media-small-desktop() {
    margin-bottom: $metrics-s;
  }
}

.Description {
  @include Font-Body;
  margin: $metrics-s 0 $metrics-sm 0 ;
}

.Accordion {
  padding-top: 0;
  width: 100%;

  &.panel-collapse a {
    font-weight: 400;
    color: $color-primary;
    text-decoration: none;
  }
}


